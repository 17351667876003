import { useState, useRef, useContext, useEffect } from "react";

import {
	InputLabel,
	Modal,
	TextField,
	Select,
	MenuItem,
	FormControl,
	Alert,
	AlertTitle,
} from "@mui/material";
import { DateField, DatePicker } from "@mui/x-date-pickers";
import { useToasts } from "react-toast-notifications";
import {
	CountriesList,
	CovidVaccinationDosage,
	Diseases,
	HairColours,
	SkinColours,
} from "../../Assets/Data";
import dayjs from "dayjs";

import Confirmation from "../Confirmation";
import { FetchData, UploadFile } from "../../API/FetchData";
import { Endpoints } from "../../API/Endpoints";
import { DefaultContext } from "../Dashboard";
import { validatePhone } from "../../Lib/Validate";
import { validateBVN, validateEmail, validateNIN } from "../../App";
import ImageSelectorPlaceholder from "../../Assets/IMG/ImageSelectorPlaceholder.svg";
import { PerformRequest } from "../../API/PerformRequests";

const experiences = [
	{
		name: "None",
		value: "0"
	},
	{
		name: "Once",
		value: "1"
	},
	{
		name: "Twice",
		value: "2"
	},
	{
		name: "Three Times",
		value: "3"
	}
]

const initialSurrogateForm = {
	firstName: "",
	lastName: "",
	dateOfBirth: dayjs("2023-01-01"),
	placeOfBirth: "Nigeria",
	address: "",
	primaryPhone: "",
	primaryEmailAddress: "",
	bankVerificationNumber: "",
	nationalIdentificationNumber: "",
	primaryImage: undefined,
	secondaryImage: undefined,

	tribe: "",
	religion: "",
	experience: "0",
	spouseFirstName: "",
	spouseLastName: "",
	secondaryEmailAddress: "",
	secondaryPhone: "",
	sibling: {
		fullname: "",
		relationship: "Brother",
		phone: "",
	},
	// Form Section B
	knownDisease: "None",
	covidVaccination: "0",
	firstTimeParent: "No",
	lastChildBirth: dayjs("2023-01-01"),
	hivStatus: "Negative",


	govtIdentificationFile: undefined,
	covidVaccinationFile: undefined,
	nextOfKin: {
		name: "",
		address: "",
		phone: "",
		relationship: "Father",
		nationalIdentificationNumber: "",

		area: "",
		state: "",
		city: "",
		country: "Nigeria",
	},

	hepatitisStatus: "Negative",
	weight: "",
	height: "",
	bmi: "",
	bloodGroup: "",
	genoType: "",

	area: "",
	state: "",
	city: "",
	country: "Nigeria",
};

export default function SurrogateRegistration({ showAddSurrogateModal }) {
	const { addToast, removeAllToasts } = useToasts();
	const instructionRef = useRef(null)

	const [errors, setErrors] = useState([])

	const [isModalOpen, setModalOpen] = useState(true);
	const consumeContext = useContext(DefaultContext);

	const { Tribes, Religions, BloodGroups, GeneTypes } = consumeContext;
	console.log({ Tribes, Religions });
	const [countriesList, setCountriesList] = useState(CountriesList);

	const [currentFormSection, setCurrentFormSection] = useState(1);
	const [surrogateForm, setSurrogateForm] = useState(initialSurrogateForm);
	const [formSubmitting, setFormSubmitting] = useState(false);
	const [formErrors, setFormErrors] = useState({
		firstName: false,
		lastName: false,
		dateOfBirth: false,
		placeOfBirth: false,
		address: false,
		tribe: false,
		religion: false,
		experience: false,
		primaryPhone: false,
		primaryEmailAddress: false,
		bankVerificationNumber: false,
		nationalIdentificationNumber: false,
		primaryImage: false,
		secondaryImage: false,

		spouseFirstName: false,
		spouseLastName: false,
		secondaryEmailAddress: false,
		secondaryPhone: false,

		// Form Section B
		knownDisease: false,
		covidVaccination: false,
		firstTimeParent: false,
		lastChildBirth: false,
		hivStatus: false,
		govtIdentificationFile: false,
		covidVaccinationFile: false,
		nextOfKin_name: false,
		nextOfKin_phone: false,
		nextOfKin_address: false,
		nextOfKin_nationalIdentificationNumber: false,
		nextOfKin_area: false,
		nextOfKin_state: false,
		nextOfKin_city: false,
		nextOfKin_country: false,

		// sibling_name: false,
		// sibling_phone: false,

		hepatitisStatus: false,
		weight: false,
		height: false,
		bmi: false,
		bloodGroup: false,
		genoType: false,

		area: false,
		state: false,
		city: false,
		country: false,
	});
	const primaryImageUploadRef = useRef();
	const secondaryImageUploadRef = useRef();

	const govtIdentificationUploadRef = useRef();
	const covidVaccinationUploadRef = useRef();
	const defaultFullInputProps = {
		variant: "standard",
		spellCheck: false,
		className: "modal-input-full px-14",
	};
	const defaultHalfInputProps = {
		variant: "standard",
		className: "modal-input-half px-14",
		spellCheck: false,
	};
	const fileIsLarge = () => {
		addToast("Max File Size: 1.5MB", { appearance: "error" });
	};

	const resetFormErrors = () => {
		setFormErrors({
			firstName: false,
			lastName: false,
			dateOfBirth: false,
			placeOfBirth: false,
			address: false,
			tribe: false,
			religion: false,
			experience: false,
			primaryPhone: false,
			primaryEmailAddress: false,
			bankVerificationNumber: false,
			nationalIdentificationNumber: false,
			primaryImage: false,
			secondaryImage: false,

			spouseFirstName: false,
			spouseLastName: false,
			secondaryEmailAddress: false,
			secondaryPhone: false,

			// Form Section B
			knownDisease: false,
			covidVaccination: false,
			firstTimeParent: false,
			lastChildBirth: false,
			hivStatus: false,
			govtIdentificationFile: false,
			covidVaccinationFile: false,
			nextOfKin_name: false,
			nextOfKin_phone: false,
			nextOfKin_address: false,
			nextOfKin_nationalIdentificationNumber: false,
			nextOfKin_area: false,
			nextOfKin_state: false,
			nextOfKin_city: false,
			nextOfKin_country: false,

			// sibling_name: false,
			// sibling_phone: false,

			hepatitisStatus: false,
			weight: false,
			height: false,
			bmi: false,
			bloodGroup: false,
			genoType: false,

			area: false,
			state: false,
			city: false,
			country: false,
		})
	}



	const scrollToInstruction = () => {
		if (instructionRef.current) {
			instructionRef.current.scrollTo({
				top: 0,
				behavior: 'smooth', // Optional for smooth scrolling
			});
		}
	}

	const UpdateFormErrors = () => {
		const newErrors = []

		const isPrimaryPhoneValid = validatePhone(surrogateForm.primaryPhone);
		if (!isPrimaryPhoneValid) newErrors.push("Primary phone is not valid")

		const isEmailValid = validateEmail(surrogateForm.primaryEmailAddress);
		if (!isEmailValid) newErrors.push("Email is not valid")

		const is_BVN_Valid = validateBVN(surrogateForm.bankVerificationNumber);
		if (!is_BVN_Valid) newErrors.push("BVN is not valid")

		const is_NIN_Valid = validateNIN(
			surrogateForm.nationalIdentificationNumber
		);
		if (!is_NIN_Valid) newErrors.push("NIN is not valid")

		const isNexfOfKinPrimaryPhoneValid =
			validatePhone(surrogateForm.nextOfKin.phone) &&
			surrogateForm.primaryPhone !== surrogateForm.nextOfKin.phone
		if (!isNexfOfKinPrimaryPhoneValid) newErrors.push("Next of Kin Phone is not valid")

		const isSiblingPhoneValid =
			validatePhone(surrogateForm.sibling.phone) &&
			surrogateForm.primaryPhone !== surrogateForm.sibling.phone &&
			surrogateForm.sibling.phone !== surrogateForm.nextOfKin.phone;

		const isNexfOfKinNINValid = validateNIN(
			surrogateForm.nextOfKin.nationalIdentificationNumber
		);

		if (!isNexfOfKinPrimaryPhoneValid) newErrors.push("Next of Kin NIN is not valid")

		if (surrogateForm.firstName.length === 0) newErrors.push("First Name is required")

		if (surrogateForm.lastName.length === 0) newErrors.push("Last Name is required")

		if (surrogateForm.address.length === 0) newErrors.push("Address is required")

		if (surrogateForm.area.length === 0) newErrors.push("Area is required")
		if (surrogateForm.state.length === 0) newErrors.push("State is required")
		if (surrogateForm.city.length === 0) newErrors.push("City is required")
		if (surrogateForm.country.length === 0) newErrors.push("Country is required")


		if (surrogateForm.tribe.length === 0) newErrors.push("Tribe is required")

		if (surrogateForm.religion.length === 0) newErrors.push("Religion is required")

		if (surrogateForm.experience.length === 0) newErrors.push("Experience is required")



		if (surrogateForm.nextOfKin.name.length === 0) newErrors.push("Next of Kin Name is required")

		if (surrogateForm.nextOfKin.address.length === 0) newErrors.push("Next of Kin Address is required")


		if (surrogateForm.nextOfKin.area.length === 0) newErrors.push("Next of Kin Area is required")
		if (surrogateForm.nextOfKin.state.length === 0) newErrors.push("Next of Kin State is required")
		if (surrogateForm.nextOfKin.city.length === 0) newErrors.push("Next of Kin City is required")
		if (surrogateForm.nextOfKin.country.length === 0) newErrors.push("Next of Kin Country is required")


		if (!surrogateForm.primaryImage) newErrors.push("Primary Image is required")

		if (!surrogateForm.secondaryImage) newErrors.push("Secondary Image  is required")

		if (!surrogateForm.govtIdentificationFile) newErrors.push("Government Identification is required")

		if (!surrogateForm.covidVaccinationFile) newErrors.push("Covid Vaccination File is required")

		if (surrogateForm.hepatitisStatus.length === 0) newErrors.push("Hepatitis Status is required")

		if (surrogateForm.weight.length === 0) newErrors.push("Weight is required")

		if (surrogateForm.height.length === 0) newErrors.push("Height is required")


		if (surrogateForm.bmi.length === 0) newErrors.push("BMI is required")

		if (surrogateForm.bloodGroup.length === 0) newErrors.push("Blood Group is required")

		if (surrogateForm.genoType.length === 0) newErrors.push("Genotype is required")

		setErrors(newErrors)


		const concludedErrors = {
			...formErrors,
			firstName: surrogateForm.firstName.length === 0,
			lastName: surrogateForm.lastName.length === 0,
			address: surrogateForm.address.length === 0,

			area: surrogateForm.area.length === 0,
			state: surrogateForm.state.length === 0,
			city: surrogateForm.city.length === 0,
			country: surrogateForm.country.length === 0,


			primaryPhone: !isPrimaryPhoneValid,
			primaryEmailAddress: !isEmailValid,
			bankVerificationNumber: !is_BVN_Valid,
			nationalIdentificationNumber: !is_NIN_Valid,

			tribe: surrogateForm.tribe.length === 0,
			religion: surrogateForm.religion.length === 0,
			experience: surrogateForm.experience.length === 0,

			// sibling_phone: !isSiblingPhoneValid,
			// sibling_name: surrogateForm.sibling.fullname.length === 0,

			nextOfKin_name: surrogateForm.nextOfKin.name.length === 0,
			nextOfKin_address: surrogateForm.nextOfKin.address.length === 0,
			nextOfKin_phone: !isNexfOfKinPrimaryPhoneValid,
			nextOfKin_nationalIdentificationNumber: !isNexfOfKinNINValid,

			nextOfKin_area: surrogateForm.nextOfKin.area.length === 0,
			nextOfKin_state: surrogateForm.nextOfKin.state.length === 0,
			nextOfKin_city: surrogateForm.nextOfKin.city.length === 0,
			nextOfKin_country: surrogateForm.nextOfKin.country.length === 0,

			primaryImage: !surrogateForm.primaryImage,
			secondaryImage: !surrogateForm.secondaryImage,

			govtIdentificationFile: !surrogateForm.govtIdentificationFile,
			covidVaccinationFile: !surrogateForm.covidVaccinationFile,


			hepatitisStatus: surrogateForm.hepatitisStatus.length === 0,
			weight: surrogateForm.weight.length === 0,
			height: surrogateForm.height.length === 0,
			bmi: surrogateForm.bmi.length === 0,
			bloodGroup: surrogateForm.bloodGroup.length === 0,
			genoType: surrogateForm.genoType.length === 0,
		}

		setFormErrors(concludedErrors);
		return concludedErrors
		// console.log(formErrors);
	};


	const [showConfirmationModal, setShowConfirmationModal] = useState(false);

	// useEffect(() => {
	// 	if (Object.values(formErrors).some((item) => item === true)) {
	// 		scrollToInstruction()
	// 	}
	// 	console.log(formErrors)
	// }, [formErrors]);

	const CreateSurrogateProfile = async () => {
		console.log(surrogateForm)
		setErrors([])
		resetFormErrors()

		let concludedErrors = UpdateFormErrors()

		const newErrors = Object.values(concludedErrors).filter((e) => e === true);

		if (newErrors.length > 0) {
			scrollToInstruction()
			setFormSubmitting(false);
			addToast("Please fill the form correctly", { appearance: "error" });
		} else {
			// setFormSubmitting(false);
			setFormSubmitting(true);
			let primaryImageFormData = new FormData();
			primaryImageFormData.append(
				"file",
				surrogateForm.primaryImage,
				surrogateForm.primaryImage.name
					.toLowerCase()
					.split(" ")
					.join()
					.replaceAll(",", "")
			);

			let secondaryImageFormData = new FormData();
			secondaryImageFormData.append(
				"file",
				surrogateForm.secondaryImage,
				surrogateForm.secondaryImage.name
					.toLowerCase()
					.split(" ")
					.join()
					.replaceAll(",", "")
			);

			let govtIDFormData = new FormData();
			govtIDFormData.append(
				"file",
				surrogateForm.govtIdentificationFile,
				surrogateForm.govtIdentificationFile.name
					.toLowerCase()
					.split(" ")
					.join()
					.replaceAll(",", "")
			);
			let covidVaccinationFormData = new FormData();
			covidVaccinationFormData.append(
				"file",
				surrogateForm.covidVaccinationFile,
				surrogateForm.covidVaccinationFile.name
					.toLowerCase()
					.split(" ")
					.join()
					.replaceAll(",", "")
			);

			const uploadPrimaryImage = await UploadFile({
				formData: primaryImageFormData,
			});
			const uploadSecondaryImage = await UploadFile({
				formData: secondaryImageFormData,
			});
			const uploadGovtId = await UploadFile({
				formData: govtIDFormData,
			});
			const uploadCovidVaccination = await UploadFile({
				formData: covidVaccinationFormData,
			});
			console.log(uploadCovidVaccination);
			const {
				name: nextOfKin_name,
				address: nextOfKin_address,
				phone: nextOfKin_phone,
				nationalIdentificationNumber: nextOfKin_nationalIdentificationNumber,
				relationship: nextOfKin_relationship,

				area: nextOfKin_area,
				state: nextOfKin_state,
				city: nextOfKin_city,
				country: nextOfKin_country,
			} = surrogateForm.nextOfKin;
			const data = {
				firstname: surrogateForm.firstName,
				lastname: surrogateForm.lastName,
				email: surrogateForm.primaryEmailAddress,
				phone: surrogateForm.primaryPhone,
				address: surrogateForm.address,

				area: surrogateForm.area,
				state: surrogateForm.state,
				city: surrogateForm.city,
				country: surrogateForm.country,

				dateOfBirth: dayjs(surrogateForm.dateOfBirth).format("YYYY-MM-DD"),
				tribe: surrogateForm.tribe,
				religion: surrogateForm.religion,
				experience: surrogateForm.experience,
				placeOfBirth: surrogateForm.placeOfBirth,
				mainImage: uploadPrimaryImage.data.fileUrl,
				secondImage: uploadSecondaryImage.data.fileUrl,
				nin: surrogateForm.nationalIdentificationNumber,
				bvn: surrogateForm.bankVerificationNumber,
				governmentID: uploadGovtId.data.fileUrl,

				nok: {
					fullname: nextOfKin_name,
					relationship: nextOfKin_relationship,
					address: nextOfKin_address,
					phone: nextOfKin_phone,
					nin: nextOfKin_nationalIdentificationNumber,

					area: nextOfKin_area,
					state: nextOfKin_state,
					city: nextOfKin_city,
					country: nextOfKin_country,
				},
				sibling: {
					fullname: surrogateForm.sibling.fullname,
					relationship: surrogateForm.sibling.relationship,
					phone: surrogateForm.sibling.phone,
				},

				extendedInfo: {
					disease: surrogateForm.knownDisease,
					covidVaccination: surrogateForm.covidVaccination,
					covidVaccinationCertificate: uploadCovidVaccination.data.fileUrl,
					firstTimeParent: surrogateForm.firstTimeParent,
					hivStatus: surrogateForm.hivStatus,
					lastChildBirthTime: dayjs(surrogateForm.lastChildBirth).format(
						"YYYY-MM-DD"
					),
					hepatitisStatus: surrogateForm.hepatitisStatus,
					weight: surrogateForm.weight,
					height: surrogateForm.height,
					bmi: surrogateForm.bmi,
					bloodGroup: surrogateForm.bloodGroup,
					genoType: surrogateForm.genoType,
				},
			};

			console.log("Data: ", data);
			const createSurrogateRequest = await PerformRequest.CreateNewSurrogate(
				data
			).catch(() => {
				setFormSubmitting(false);
			});
			setFormSubmitting(false);
			console.log(createSurrogateRequest);
			removeAllToasts();
			const { message: responseMessage } = createSurrogateRequest.data;
			if (createSurrogateRequest.data.status === "failed") {
				addToast(responseMessage, { appearance: "error" });
			} else {
				addToast(responseMessage, { appearance: "success" });
				window.location.reload();
			}
		}

		// setModalOpen(false);
		// setShowConfirmationModal(true);
	};

	const getConfirmationModalStatus = (value) => {
		setShowConfirmationModal(value);
		if (!value) {
			showAddSurrogateModal(false);
		}
	};
	return (
		<>
			{showConfirmationModal && (
				<Confirmation
					modalHeaderText=""
					modalBodyText="Profile Created Successfully"
					modalAction={{
						method: () => {
							setShowConfirmationModal(false);
							setModalOpen(true);
							setSurrogateForm(initialSurrogateForm);
							setCurrentFormSection(1);
						},
						text: "Create Another Profile",
					}}
					modalLink={{
						text: "Back to Dashboard",
						route: "/dashboard",
					}}
					getConfirmationModalStatus={getConfirmationModalStatus}
				/>
			)}
			<input
				type="file"
				accept=".jpg, .png"
				ref={primaryImageUploadRef}
				className="modal-image-hide"
				onChange={(e) => {
					const image = e.target.files[0];
					console.log(image);
					if (image.size > 1547220) {
						fileIsLarge();
					} else {
						setSurrogateForm({
							...surrogateForm,
							primaryImage: image,
						});
						setFormErrors({
							...formErrors,
							primaryImage: false
						});
					}
				}}
			/>
			<input
				type="file"
				accept=".jpg, .png"
				ref={secondaryImageUploadRef}
				className="modal-image-hide"
				onChange={(e) => {
					console.log(e.target.files);
					const image = e.target.files[0];
					if (image.size > 1547220) {
						fileIsLarge();
					} else {
						setSurrogateForm({
							...surrogateForm,
							secondaryImage: image,
						});
						setFormErrors({
							...formErrors,
							secondaryImage: false
						});
					}
				}}
			/>
			<input
				type="file"
				accept=".pdf, .jpg, .png"
				ref={govtIdentificationUploadRef}
				className="modal-image-hide"
				onChange={(e) => {
					const file = e.target.files[0];
					console.log(file);
					if (file.size > 1547220) {
						fileIsLarge();
					} else {
						setSurrogateForm({
							...surrogateForm,
							govtIdentificationFile: file,
						});
						setFormErrors({
							...formErrors,
							govtIdentificationFile: false
						});
					}
				}}
			/>
			<input
				type="file"
				accept=".pdf, .jpg, .png"
				ref={covidVaccinationUploadRef}
				className="modal-image-hide"
				onChange={(e) => {
					console.log(e.target.files);
					const file = e.target.files[0];
					if (file.size > 1547220) {
						fileIsLarge();
					} else {
						setSurrogateForm({
							...surrogateForm,
							covidVaccinationFile: file,
						});
						setFormErrors({
							...formErrors,
							covidVaccinationFile: false
						});
					}
				}}
			/>
			<Modal
				open={isModalOpen}
				onClose={(e, reason) => {
					if (reason === "backdropClick") {
						setModalOpen(false);
						showAddSurrogateModal(false);
					}
				}}
				className="default-modal-container flex-row"
			>
				<div className="default-modal-content modal-scrollbar surrogate-report-modal flex-column" ref={instructionRef}>
					<span className="cinzel px-30 uppercase">
						create surrogate profile
					</span>
					<br />
					<span className="modal-about poppins px-15" >
						Fill in the data for surrogate profile correctly. It will take a
						couple of minutes
					</span>

					{
						errors.length > 0 ?
							<div style={{
								marginTop: "1rem",
								marginBottom: "1rem",
							}}>
								<Alert severity="warning">
									<AlertTitle>Validation Error</AlertTitle>
									{
										errors.map((item, index) => (
											<p key={`error-${index}`}>{item}</p>
										))
									}
								</Alert>
							</div>
							: null
					}

					{currentFormSection === 1 ? (
						<div className="modal-form-container flex-row">
							<div className="modal-form flex-column">
								<br />
								<span className="fw-600 poppins px-24">Surrogate Bio-Data</span>
								<br />
								<div className="flex-row space-between modal-input-row">
									<TextField
										label="First Name"
										value={surrogateForm.firstName}
										error={formErrors.firstName}
										{...defaultHalfInputProps}
										onChange={(e) => {
											setSurrogateForm({
												...surrogateForm,
												firstName: e.target.value,
											})
											setFormErrors({
												...formErrors,
												firstName: false
											});
										}}
										placeholder="First Name"
									/>
									<TextField
										label="Last Name"
										value={surrogateForm.lastName}
										error={formErrors.lastName}
										{...defaultHalfInputProps}
										onChange={(e) => {
											setSurrogateForm({
												...surrogateForm,
												lastName: e.target.value,
											})
											setFormErrors({
												...formErrors,
												lastName: false
											});
										}}
										placeholder="Last Name"
									/>
								</div>
								<div className="flex-row space-between modal-input-row">
									<DatePicker
										defaultValue={dayjs("2023-01-01")}
										{...defaultHalfInputProps}
										slotProps={{
											textField: { variant: "standard" },
										}}
										value={dayjs(surrogateForm.dateOfBirth)}
										onChange={(e) => {
											setSurrogateForm({
												...surrogateForm,
												dateOfBirth: dayjs(e).format("YYYY-MM-DD"),
											});
											setFormErrors({
												...formErrors,
												dateOfBirth: false
											});
										}}
										label="Date of Birth"
										placeholder="Date of Birth"
									/>
									<FormControl variant="standard" {...defaultHalfInputProps}>
										<InputLabel id="demo-simple-select-standard-label">
											Place of Birth
										</InputLabel>
										<Select
											labelId="demo-simple-select-standard-label"
											id="demo-simple-select-standard"
											value={surrogateForm.placeOfBirth}
											onChange={(e) => {
												setSurrogateForm({
													...surrogateForm,
													placeOfBirth: e.target.value,
												});
												setFormErrors({
													...formErrors,
													placeOfBirth: false
												});
											}}
											label="Place of Birth"
											placeholder="Place of Birth"
										>
											{countriesList.map((country, index) => {
												return (
													<MenuItem value={country.name} key={country.name}>
														{country.name}
													</MenuItem>
												);
											})}
										</Select>
									</FormControl>
								</div>
								<div className="flex-row space-between modal-input-row">
									<TextField
										label="Area"
										value={surrogateForm.area}
										error={formErrors.area}
										{...defaultFullInputProps}
										onChange={(e) => {
											setSurrogateForm({
												...surrogateForm,
												area: e.target.value,
											})
											setFormErrors({
												...formErrors,
												area: false
											});
										}}
										placeholder="Area"
									/>
								</div>
								<div className="flex-row space-between modal-input-row">
									<TextField
										label="Address"
										value={surrogateForm.address}
										error={formErrors.address}
										{...defaultFullInputProps}
										onChange={(e) => {
											setSurrogateForm({
												...surrogateForm,
												address: e.target.value,
											})
											setFormErrors({
												...formErrors,
												address: false
											});
										}}
										placeholder="Address"
									/>
								</div>
								<div className="flex-row space-between modal-input-row">
									<TextField
										label="State"
										value={surrogateForm.state}
										error={formErrors.state}
										{...defaultHalfInputProps}
										onChange={(e) => {
											setSurrogateForm({
												...surrogateForm,
												state: e.target.value,
											})
											setFormErrors({
												...formErrors,
												state: false
											});
										}}
										placeholder="State"
									/>
									<TextField
										label="City"
										value={surrogateForm.city}
										error={formErrors.city}
										{...defaultHalfInputProps}
										onChange={(e) => {
											setSurrogateForm({
												...surrogateForm,
												city: e.target.value,
											})
											setFormErrors({
												...formErrors,
												city: false
											});
										}}
										placeholder="City"
									/>
								</div>
								<div className="flex-row space-between modal-input-row">
									<FormControl variant="standard" {...defaultFullInputProps}>
										<InputLabel id="demo-simple-select-standard-label">
											Country
										</InputLabel>
										<Select
											labelId="demo-simple-select-standard-label"
											id="demo-simple-select-standard"
											value={surrogateForm.country}
											onChange={(e) => {
												setSurrogateForm({
													...surrogateForm,
													country: e.target.value,
												});
												setFormErrors({
													...formErrors,
													country: false
												});
											}}
											label="Country"
											placeholder="Country"
										>
											{countriesList.map((country, index) => {
												return (
													<MenuItem value={country.name} key={`${country.name}-${index}-country`}>
														{country.name}
													</MenuItem>
												);
											})}
										</Select>
									</FormControl>
								</div>
								<div className="flex-row space-between modal-input-row">
									<FormControl variant="standard" {...defaultFullInputProps}>
										<InputLabel id="demo-simple-select-standard-label">
											How many times have you been a surrogate?
										</InputLabel>
										<Select
											labelId="demo-simple-select-standard-label"
											id="demo-simple-select-standard"
											value={surrogateForm.experience}
											onChange={(e) => {
												setSurrogateForm({
													...surrogateForm,
													experience: e.target.value,
												});
												setFormErrors({
													...formErrors,
													experience: false
												});
											}}
											label="How many times have you been a surrogate?"
											placeholder="How many times have you been a surrogate?"
										>
											{experiences.map((item, index) => {
												return (
													<MenuItem value={item.value} key={`${item.name}-${index}-country`}>
														{item.name}
													</MenuItem>
												);
											})}
										</Select>
									</FormControl>
								</div>
								<div className="flex-row space-between modal-input-row">
									<TextField
										label="Primary Phone Number"
										placeholder="0802-345-6789"
										value={surrogateForm.primaryPhone}
										error={formErrors.primaryPhone}
										{...defaultFullInputProps}
										onChange={(e) => {
											setSurrogateForm({
												...surrogateForm,
												primaryPhone: e.target.value,
											})
											setFormErrors({
												...formErrors,
												primaryPhone: false
											});
										}}
									// onBlur={() => {
									//   setSurrogateForm({
									//     ...surrogateForm,
									//     primaryPhone: surrogateForm.primaryPhone.replace(
									//       "+",
									//       ""
									//     ),
									//   });
									// }}
									/>
								</div>
								<div className="flex-row space-between modal-input-row">
									<TextField
										label="Primary Email Address"
										value={surrogateForm.primaryEmailAddress}
										error={formErrors.primaryEmailAddress}
										{...defaultFullInputProps}
										onChange={(e) => {
											setSurrogateForm({
												...surrogateForm,
												primaryEmailAddress: e.target.value,
											})
											setFormErrors({
												...formErrors,
												primaryEmailAddress: false
											});
										}}
										placeholder="Primary Email Address"
									/>
								</div>
								<div className="flex-row space-between modal-input-row">
									<TextField
										inputProps={{
											maxLength: 11,
										}}
										label="Bank Verification Number"
										value={surrogateForm.bankVerificationNumber}
										error={formErrors.bankVerificationNumber}
										{...defaultFullInputProps}
										onChange={(e) => {
											setSurrogateForm({
												...surrogateForm,
												bankVerificationNumber: e.target.value,
											})
											setFormErrors({
												...formErrors,
												bankVerificationNumber: false
											});
										}}
										placeholder="Bank Verification Number"
									/>
								</div>
								<div className="flex-row space-between modal-input-row">
									<TextField
										label="National Identification Number"
										inputProps={{
											maxLength: 11,
										}}
										value={surrogateForm.nationalIdentificationNumber}
										error={formErrors.nationalIdentificationNumber}
										{...defaultFullInputProps}
										onChange={(e) => {
											setSurrogateForm({
												...surrogateForm,
												nationalIdentificationNumber: e.target.value,
											})
											setFormErrors({
												...formErrors,
												nationalIdentificationNumber: false
											});
										}}
										placeholder="National Identification Number"
									/>
								</div>
							</div>

							<div className="flex-column modal-form-right space-between">
								<span className="flex-column align-center width-100">
									<div className="modal-form-image-container modal-form-image-container-small flex-row">
										{surrogateForm.primaryImage ? (
											<img
												src={URL.createObjectURL(surrogateForm.primaryImage)}
												alt=""
												className="modal-form-image"
											/>
										) : (
											<span className="px-16 poppins">No Image Selected</span>
										)}
									</div>
									<br />
									<span
										className="purple-btn-default px-16 poppins pointer width-100 surrogate-form-btn"
										onClick={() => {
											primaryImageUploadRef.current.click();
										}}
										style={{
											borderColor: formErrors.primaryImage
												? "red"
												: "transparent",
										}}
									>
										Upload Main Image
									</span>
									<br />
									<div className="modal-form-image-container modal-form-image-container-small flex-row">
										{surrogateForm.secondaryImage ? (
											<img
												src={URL.createObjectURL(surrogateForm.secondaryImage)}
												alt=""
												className="modal-form-image"
											/>
										) : (
											<span className="px-16 poppins">No Image Selected</span>
										)}
									</div>
									<br />
									<span
										className="purple-btn-default px-16 poppins pointer width-100 surrogate-form-btn"
										onClick={() => {
											secondaryImageUploadRef.current.click();
										}}
										style={{
											borderColor: formErrors.secondaryImage
												? "red"
												: "transparent",
										}}
									>
										Upload Second Image
									</span>
								</span>
								<br />
								<span
									className="purple-btn-default px-16 poppins pointer width-100 uppercase modal-form-submit surrogate-form-btn"
									onClick={() => {
										setCurrentFormSection(2);
										scrollToInstruction()
									}}
								>
									Next Step &nbsp; <i className="far fa-long-arrow-alt-right" />
								</span>
							</div>
						</div>
					) : (
						<div className="modal-form-container flex-row">
							<div className="modal-form flex-column">
								<br />
								<span className="fw-600 poppins px-24">
									Surrogate Health Information
								</span>
								<br />

								<div className="flex-row space-between modal-input-row">
									<FormControl variant="standard" {...defaultHalfInputProps}>
										<InputLabel id="demo-simple-select-standard-label">
											Any Known Disease/Ailment
										</InputLabel>
										<Select
											labelId="demo-simple-select-standard-label"
											id="demo-simple-select-standard"
											value={surrogateForm.knownDisease}
											onChange={(e) => {
												setSurrogateForm({
													...surrogateForm,
													knownDisease: e.target.value,
												});
												setFormErrors({
													...formErrors,
													knownDisease: false
												});
											}}
											label="Any Known Disease/Ailment"
											placeholder="Any Known Disease/Ailment"
										>
											{Diseases.map((disease, index) => {
												return (
													<MenuItem value={disease.value} key={disease.value}>
														{disease.name}
													</MenuItem>
												);
											})}
										</Select>
									</FormControl>
									<FormControl variant="standard" {...defaultHalfInputProps}>
										<InputLabel id="demo-simple-select-standard-label">
											Taken COVID Vaccination
										</InputLabel>
										<Select
											labelId="demo-simple-select-standard-label"
											id="demo-simple-select-standard"
											value={surrogateForm.covidVaccination}
											onChange={(e) => {
												setSurrogateForm({
													...surrogateForm,
													covidVaccination: e.target.value,
												});
												setFormErrors({
													...formErrors,
													covidVaccination: false
												});
											}}
											label="Taken Covid Vaccination"
											placeholder="Taken Covid Vaccination"
										>
											{CovidVaccinationDosage.map((vaccination, index) => {
												return (
													<MenuItem
														value={vaccination.value}
														key={vaccination.value}
													>
														{vaccination.name}
													</MenuItem>
												);
											})}
										</Select>
									</FormControl>
								</div>
								<div className="flex-row space-between modal-input-row">
									<DatePicker
										defaultValue={dayjs("2023-01-01")}
										{...defaultFullInputProps}
										slotProps={{
											textField: { variant: "standard" },
										}}
										value={dayjs(surrogateForm.lastChildBirth)}
										onChange={(e) => {
											setSurrogateForm({
												...surrogateForm,
												lastChildBirth: dayjs(e).format("YYYY-MM-DD"),
											});
											setFormErrors({
												...formErrors,
												lastChildBirth: false
											});
										}}
										label="Date of Last Child Birth"
									/>
								</div>

								<div className="flex-row space-between modal-input-row">
									<FormControl variant="standard" {...defaultHalfInputProps}>
										<InputLabel id="demo-simple-select-standard-label">
											HIV/AIDS STATUS
										</InputLabel>
										<Select
											labelId="demo-simple-select-standard-label"
											id="demo-simple-select-standard"
											value={surrogateForm.hivStatus}
											onChange={(e) => {
												setSurrogateForm({
													...surrogateForm,
													hivStatus: e.target.value,
												});
												setFormErrors({
													...formErrors,
													hivStatus: false
												});
											}}
											label="HIV/AIDS STATUS"
											placeholder="HIV/AIDS STATUS"
										>
											<MenuItem value={"Negative"} key="hiv-status-false">
												Negative
											</MenuItem>
											<MenuItem value={"Positive"} key="hiv-status-true">
												Positive
											</MenuItem>
										</Select>
									</FormControl>
									<FormControl variant="standard" {...defaultHalfInputProps}>
										<InputLabel id="demo-simple-select-standard-label">
											First Time Parent?
										</InputLabel>
										<Select
											labelId="demo-simple-select-standard-label"
											id="demo-simple-select-standard"
											value={surrogateForm.firstTimeParent}
											onChange={(e) => {
												setSurrogateForm({
													...surrogateForm,
													firstTimeParent: e.target.value,
												});
												setFormErrors({
													...formErrors,
													firstTimeParent: false
												});
											}}
											label="First Time Parent?"
											placeholder="First Time Parent?"
										>
											<MenuItem value={"No"} key="first-time-parent-false">
												No
											</MenuItem>
											<MenuItem value={"Yes"} key="first-time-parent-true">
												Yes
											</MenuItem>
										</Select>
									</FormControl>
								</div>

								<div className="flex-row space-between modal-input-row">
									<FormControl variant="standard" {...defaultHalfInputProps}>
										<InputLabel id="demo-simple-select-standard-label">
											⁠⁠Hepatitis Status
										</InputLabel>
										<Select
											labelId="demo-simple-select-standard-label"
											id="demo-simple-select-standard"
											value={surrogateForm.hepatitisStatus}
											onChange={(e) => {
												setSurrogateForm({
													...surrogateForm,
													hepatitisStatus: e.target.value,
												});
												setFormErrors({
													...formErrors,
													hepatitisStatus: false
												});
											}}
											label="HIV/AIDS STATUS"
											placeholder="HIV/AIDS STATUS"
										>
											<MenuItem value={"Negative"} key="hiv-status-false">
												Negative
											</MenuItem>
											<MenuItem value={"Positive"} key="hiv-status-true">
												Positive
											</MenuItem>
										</Select>
									</FormControl>

									<TextField
										label="Weight"
										{...defaultHalfInputProps}
										value={surrogateForm.weight}
										error={formErrors.weight}
										onChange={(e) => {
											setSurrogateForm({
												...surrogateForm,
												weight: e.target.value,
											})
											setFormErrors({
												...formErrors,
												weight: false
											});
										}}
										placeholder="Weight in KG"
									/>
								</div>

								<div className="flex-row space-between modal-input-row" style={{
									gap: 30
								}}>
									<TextField
										label="Height"
										{...defaultFullInputProps}
										value={surrogateForm.height}
										error={formErrors.height}
										onChange={(e) => {
											setSurrogateForm({
												...surrogateForm,
												height: e.target.value,
											})
											setFormErrors({
												...formErrors,
												height: false
											});
										}}
										placeholder="Height in CM"
									/>

									<TextField
										label="BMI"
										{...defaultFullInputProps}
										value={surrogateForm.bmi}
										error={formErrors.bmi}
										onChange={(e) => {
											setSurrogateForm({
												...surrogateForm,
												bmi: e.target.value,
											})
											setFormErrors({
												...formErrors,
												bmi: false
											});
										}}
										placeholder="Body Mass Index"
									/>
								</div>

								<div className="flex-row space-between modal-input-row">
									<FormControl variant="standard" {...defaultHalfInputProps}>
										<InputLabel id="demo-simple-select-standard-label">
											Blood Group
										</InputLabel>
										<Select
											labelId="demo-simple-select-standard-label"
											id="demo-simple-select-standard"
											value={surrogateForm.bloodGroup}
											error={formErrors.bloodGroup}
											onChange={(e) => {
												setSurrogateForm({
													...surrogateForm,
													bloodGroup: e.target.value,
												});
												setFormErrors({
													...formErrors,
													bloodGroup: false
												});
											}}
											label="Blood Group"
											placeholder="Blood Group"
										>
											{BloodGroups.map((rel, index) => {
												return (
													<MenuItem value={rel.value} key={`bloodgroup-${index}`}>
														{rel.name}
													</MenuItem>
												);
											})}
										</Select>
									</FormControl>

									<FormControl variant="standard" {...defaultHalfInputProps}>
										<InputLabel id="demo-simple-select-standard-label">
											Genotype
										</InputLabel>
										<Select
											labelId="demo-simple-select-standard-label"
											id="demo-simple-select-standard"
											value={surrogateForm.genoType}
											error={formErrors.genoType}
											onChange={(e) => {
												setSurrogateForm({
													...surrogateForm,
													genoType: e.target.value,
												});
												setFormErrors({
													...formErrors,
													genoType: false
												});
											}}
											label="Genotype"
											placeholder="Genotype"
										>
											{GeneTypes.map((rel, index) => {
												return (
													<MenuItem value={rel.value} key={`genoType-${index}`}>
														{rel.name}
													</MenuItem>
												);
											})}
										</Select>
									</FormControl>
								</div>

								<div className="flex-row space-between modal-input-row">
									<FormControl variant="standard" {...defaultHalfInputProps}>
										<InputLabel id="demo-simple-select-standard-label">
											Tribe
										</InputLabel>
										<Select
											labelId="demo-simple-select-standard-label"
											id="demo-simple-select-standard"
											value={surrogateForm.tribe}
											error={formErrors.tribe}
											onChange={(e) => {
												setSurrogateForm({
													...surrogateForm,
													tribe: e.target.value,
												});
												setFormErrors({
													...formErrors,
													tribe: false
												});
											}}
											label="Tribe"
											placeholder="Tribe"
										>
											{Tribes.map((tribe) => {
												return (
													<MenuItem value={tribe.tribe} key="hiv-status-false">
														{tribe.tribe}
													</MenuItem>
												);
											})}
										</Select>
									</FormControl>
									<FormControl variant="standard" {...defaultHalfInputProps}>
										<InputLabel id="demo-simple-select-standard-label">
											Religion
										</InputLabel>
										<Select
											labelId="demo-simple-select-standard-label"
											id="demo-simple-select-standard"
											value={surrogateForm.religion}
											error={formErrors.religion}
											onChange={(e) => {
												setSurrogateForm({
													...surrogateForm,
													religion: e.target.value,
												});
												setFormErrors({
													...formErrors,
													religion: false
												});
											}}
											label="Religion"
											placeholder="Religion"
										>
											{Religions.map((rel) => {
												return (
													<MenuItem value={rel.tribe} key="hiv-status-false">
														{rel.tribe}
													</MenuItem>
												);
											})}
										</Select>
									</FormControl>
								</div>
								{/* <div className="flex-row space-between modal-input-row">
                  <TextField
                    label="Sibling Full Name"
                    {...defaultFullInputProps}
                    value={surrogateForm.sibling.fullname}
                    error={formErrors.sibling_name}
                    onChange={(e) =>
                      setSurrogateForm({
                        ...surrogateForm,
                        sibling: {
                          ...surrogateForm.sibling,
                          fullname: e.target.value,
                        },
                      })
                    }
                  />
                </div> */}
								{/* <div className="flex-row space-between modal-input-row">
                  <FormControl variant="standard" {...defaultHalfInputProps}>
                    <InputLabel id="demo-simple-select-standard-label">
                      Relationship to Sibling
                    </InputLabel>
                    <Select
                      labelId="demo-simple-select-standard-label"
                      id="demo-simple-select-standard"
                      value={surrogateForm.sibling.relationship}
                      {...defaultFullInputProps}
                      onChange={(e) =>
                        setSurrogateForm({
                          ...surrogateForm,
                          sibling: {
                            ...surrogateForm.sibling,
                            relationship: e.target.value,
                          },
                        })
                      }
                      label="Relationship to Sibling"
                    >
                      <MenuItem value={"Brother"} key="hiv-status-false">
                        Brother
                      </MenuItem>
                      <MenuItem value={"Sister"} key="hiv-status-false">
                        Sister
                      </MenuItem>
                    </Select>
                  </FormControl>

                  <TextField
                    label="Sibling Phone"
                    {...defaultHalfInputProps}
                    value={surrogateForm.sibling.phone}
                    error={formErrors.sibling_phone}
                    onChange={(e) =>
                      setSurrogateForm({
                        ...surrogateForm,
                        sibling: {
                          ...surrogateForm.sibling,
                          phone: e.target.value,
                        },
                      })
                    }
                  />

                </div> */}
								<br />
								<span className="fw-600 poppins px-24">
									Next of KIN Information
								</span>
								<br />

								<div className="flex-row space-between modal-input-row">
									<TextField
										label="Next of Kin Full Name"
										value={surrogateForm.nextOfKin.name}
										error={formErrors.nextOfKin_name}
										{...defaultFullInputProps}
										onChange={(e) => {
											setSurrogateForm({
												...surrogateForm,
												nextOfKin: {
													...surrogateForm.nextOfKin,
													name: e.target.value,
												},
											})
											setFormErrors({
												...formErrors,
												nextOfKin_name: false
											});
										}}
										placeholder="Next of Kin Full Name"
									/>
								</div>
								<div className="flex-row space-between modal-input-row">
									<TextField
										label="Area"
										value={surrogateForm.nextOfKin.area}
										error={formErrors.nextOfKin_area}
										{...defaultFullInputProps}
										onChange={(e) => {
											setSurrogateForm({
												...surrogateForm,
												nextOfKin: {
													...surrogateForm.nextOfKin,
													area: e.target.value,
												},
											})
											setFormErrors({
												...formErrors,
												nextOfKin_area: false
											});
										}}
										placeholder="Area"
									/>
								</div>
								<div className="flex-row space-between modal-input-row">
									<TextField
										label="Address"
										value={surrogateForm.nextOfKin.address}
										error={formErrors.nextOfKin_address}
										{...defaultFullInputProps}
										onChange={(e) => {
											setSurrogateForm({
												...surrogateForm,
												nextOfKin: {
													...surrogateForm.nextOfKin,
													address: e.target.value,
												},
											})
											setFormErrors({
												...formErrors,
												nextOfKin_address: false
											});
										}}
									/>
								</div>
								<div className="flex-row space-between modal-input-row">
									<TextField
										label="State"
										value={surrogateForm.nextOfKin.state}
										error={formErrors.nextOfKin_state}
										{...defaultHalfInputProps}
										onChange={(e) => {
											setSurrogateForm({
												...surrogateForm,
												nextOfKin: {
													...surrogateForm.nextOfKin,
													state: e.target.value,
												},
											})
											setFormErrors({
												...formErrors,
												nextOfKin_state: false
											});
										}}
										placeholder="State"
									/>
									<TextField
										label="City"
										value={surrogateForm.nextOfKin.city}
										error={formErrors.nextOfKin_city}
										{...defaultHalfInputProps}
										onChange={(e) => {
											setSurrogateForm({
												...surrogateForm,
												nextOfKin: {
													...surrogateForm.nextOfKin,
													city: e.target.value,
												},
											})
											setFormErrors({
												...formErrors,
												nextOfKin_city: false
											});
										}}
										placeholder="City"
									/>
								</div>
								<div className="flex-row space-between modal-input-row">
									<FormControl variant="standard" {...defaultFullInputProps}>
										<InputLabel id="demo-simple-select-standard-label">
											Country
										</InputLabel>
										<Select
											labelId="demo-simple-select-standard-label"
											id="demo-simple-select-standard"
											value={surrogateForm.nextOfKin.country}
											onChange={(e) => {
												setSurrogateForm({
													...surrogateForm,
													nextOfKin: {
														...surrogateForm.nextOfKin,
														country: e.target.value,
													},
												})
												setFormErrors({
													...formErrors,
													nextOfKin_country: false
												});
											}}
											label="Country"
											placeholder="Country"
										>
											{countriesList.map((country, index) => {
												return (
													<MenuItem value={country.name} key={`${country.name}-${index}-country-next`}>
														{country.name}
													</MenuItem>
												);
											})}
										</Select>
									</FormControl>
								</div>
								<div className="flex-row space-between modal-input-row">
									<TextField
										label="Next of Kin Phone Number"
										placeholder="0802-345-6789"
										value={surrogateForm.nextOfKin.phone}
										error={formErrors.nextOfKin_phone}
										{...defaultFullInputProps}
										onChange={(e) => {
											setSurrogateForm({
												...surrogateForm,
												nextOfKin: {
													...surrogateForm.nextOfKin,
													phone: e.target.value,
												},
											})
											setFormErrors({
												...formErrors,
												nextOfKin_phone: false
											});
										}}
									// onBlur={() => {
									//   setSurrogateForm({
									//     ...surrogateForm,
									//     nextOfKin: {
									//       ...surrogateForm.nextOfKin,
									//       phone: surrogateForm.nextOfKin.phone.replace("+", ""),
									//     },
									//   });
									// }}
									/>
								</div>
								<div className="flex-row space-between modal-input-row">
									<FormControl variant="standard" {...defaultFullInputProps}>
										<InputLabel id="demo-simple-select-standard-label">
											Relationship to Kin
										</InputLabel>
										<Select
											labelId="demo-simple-select-standard-label"
											id="demo-simple-select-standard"
											value={surrogateForm.nextOfKin.relationship}
											onChange={(e) => {
												setSurrogateForm({
													...surrogateForm,
													nextOfKin: {
														...surrogateForm.nextOfKin,
														relationship: e.target.value,
													},
												})
												setFormErrors({
													...formErrors,
													nextOfKin_relationship: false
												});
											}}
											label="Relationship to Kin"
											placeholder="Relationship to Kin"
										>
											{consumeContext.Relationships.map((rel, index) => {
												return (
													<MenuItem value={rel.relationship} key={index}>
														{rel.relationship}
													</MenuItem>
												);
											})}
										</Select>
									</FormControl>
								</div>
								<div className="flex-row space-between modal-input-row">
									<TextField
										inputProps={{
											maxLength: 11,
										}}
										label="National Identification Number"
										value={surrogateForm.nextOfKin.nationalIdentificationNumber}
										error={formErrors.nextOfKin_nationalIdentificationNumber}
										{...defaultFullInputProps}
										onChange={(e) => {
											setSurrogateForm({
												...surrogateForm,
												nextOfKin: {
													...surrogateForm.nextOfKin,
													nationalIdentificationNumber: e.target.value,
												},
											})
											setFormErrors({
												...formErrors,
												nextOfKin_nationalIdentificationNumber: false
											});
										}}
										placeholder="00000000000"
									/>
								</div>
							</div>
							<div className="flex-column modal-form-right space-between">
								<span className="flex-column align-center width-100">
									<div className="flex-column modal-form-file-container">
										<span className="px-13 poppins fw-500">
											&nbsp; &nbsp; Upload Government ID Card
										</span>
										<div className="flex-row modal-form-file width-100">
											<div className="px-13 poppins fw-500">
												{surrogateForm.govtIdentificationFile ? (
													surrogateForm.govtIdentificationFile.name
												) : (
													<span>No File Selected</span>
												)}
											</div>
											<span
												className="px-13 poppins fw-500 modal-form-file-btn flex-row pointer"
												onClick={() => {
													govtIdentificationUploadRef.current.click();
												}}
												style={{
													borderColor: formErrors.govtIdentificationFile
														? "red"
														: "transparent",
												}}
											>
												Upload File
											</span>
										</div>
										<span className="px-13 poppins fw-500 modal-form-file-about">
											&nbsp; &nbsp; Acceptable format :PDF/JPG/PNG
										</span>
									</div>
									<div className="flex-column modal-form-file-container">
										<span className="px-13 poppins fw-500">
											&nbsp; &nbsp; Upload COVID Vaccination
										</span>
										<div className="flex-row modal-form-file width-100">
											<div className="px-13 poppins fw-500">
												{surrogateForm.covidVaccinationFile ? (
													surrogateForm.covidVaccinationFile.name
												) : (
													<span>No File Selected</span>
												)}
											</div>
											<span
												className="px-13 poppins fw-500 modal-form-file-btn flex-row pointer"
												onClick={() => {
													covidVaccinationUploadRef.current.click();
												}}
												style={{
													borderColor: formErrors.covidVaccinationFile
														? "red"
														: "transparent",
												}}
											>
												Upload File
											</span>
										</div>
										<span className="px-13 poppins fw-500 modal-form-file-about">
											&nbsp; &nbsp; Acceptable format :PDF/JPG/PNG
										</span>
									</div>
								</span>
								<br />
								<div className="width-100 flex-column">
									<span
										className="purple-btn-default px-16 poppins pointer width-100 uppercase modal-form-submit surrogate-form-btn"
										onClick={() => {
											setCurrentFormSection(1);
										}}
									>
										Back &nbsp; <i className="far fa-long-arrow-alt-left" />
									</span>
									<br />
									<button
										disabled={formSubmitting}
										className="purple-btn-default px-16 poppins pointer width-100 uppercase modal-form-submit surrogate-form-btn"
										onClick={() => {
											// UpdateFormErrors();

											CreateSurrogateProfile()
										}}
									>
										Create Profile &nbsp;{" "}
										{formSubmitting ? (
											<i className="far fa-spinner-third fa-spin" />
										) : (
											<i className="far fa-long-arrow-alt-right" />
										)}
									</button>
								</div>
							</div>
						</div>
					)}
				</div>
			</Modal>
		</>
	);
}
